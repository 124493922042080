<template>
  <div class="background-image wrapper"
       v-bind:style="{backgroundImage: 'url(' + getBackgroundImageUrl + ')'}"
  >
    <div class="container">
      <h2 class="txt-aln-center">downloads</h2>
      <div class=" link-wrapper">
        <a :href="getDownloadUrl(download.download)" target="_blank" v-for="(download, index) in this.downloads"
           :key="index" class="item">
          <div class="image" v-bind:style="{backgroundImage: 'url(' + getLinkImageUrl(download.image) + ')'}"/>
          <div class="content">
            <div class="title" v-html="download.title"/>
            <div class="info" v-html="download.info"/>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {cpFullStorageUrl, cpGetContentItems} from "@/api/cockpit";

export default {
  name: "DownloadsView",
  data() {
    return {
      downloads: [],
    };
  },
  methods: {
    getDownloadUrl(download) {
      if (download) {
        return cpFullStorageUrl(download.path)
      }
      return '';
    },
    getLinkImageUrl(image) {
      if (image) {
        return cpFullStorageUrl(image.path);
      }
      return '';
    }
  },
  computed: {
    getBackgroundImageUrl() {
      return cpFullStorageUrl(this.$store.getters.getGlobalAttributeBackgroundImage.path);
    },

  },
  async created() {
    cpGetContentItems('downloads').then(data => {
      this.downloads = data;
    })
  },
}
</script>

<style scoped>

</style>
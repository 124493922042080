<template>
  <div class="background-image landing" v-bind:style="{backgroundImage: 'url(' + getBackgroundImageUrl + ')'}">
    <a :href="this.link" :target="this.target">
<!--    <img class="image" v-bind:style="{backgroundImage: 'url(' + this.imageUrl + ')'}" />-->
      <img class="image" :src="imageUrl">
    </a>
  </div>

</template>

<script>

import {cpFullStorageUrl, cpGetContentItem} from "@/api/cockpit";

export default {
  name: "HomeView",
  data() {
    return {
      imageUrl: '',
      link: '',
      target:'',
    };
  },
  computed: {
    getBackgroundImageUrl() {
      return cpFullStorageUrl(this.$store.getters.getGlobalAttributeBackgroundImage.path);
    }
  },
  created() {
    cpGetContentItem('landing').then(data => {
      console.log(data);
      if(data.image) {
        this.imageUrl = cpFullStorageUrl(data.image.path);
      }
      this.link = data.link;
      this.target = data.target;
    });

  },
}
</script>

<style scoped>
.landing {
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing .image {
  width: 50vw;
  max-width: 30rem;
  background-size: contain;
  transition: all ease var(--transition-time);
}
.landing a:hover .image {
transform: scale(1.05);
}

@media (max-width: 575px) {
  .landing .image {
    width: 75vw;
  }
}
</style>

<template>
  <part-header v-if="this.$route.name != 'linktree' || this.$route.name != 'downloads'"/>
  <router-view :key="$route.path"></router-view>
  <part-footer/>


</template>

<script>
import {cpGetContentItem, cpGetContentItems, cpGetContentTree} from "@/api/cockpit";
import PartFooter from "@/components/PartFooter";
import PartHeader from "@/components/PartHeader";

export default {
  name: 'App',
  components: {PartHeader, PartFooter},
  created() {

    //Load all Global Attributes in Vuex Store
    cpGetContentItem('globalAttributes').then(data => {
      this.$store.commit('setGlobalAttributeColor', {key: '1', hex: data.color1})
      this.$store.commit('setGlobalAttributeColor', {key: '2', hex: data.color2})
      this.$store.commit('setGlobalAttributeColor', {key: '3', hex: data.color3})
      this.$store.commit('setGlobalAttributeColor', {key: '4', hex: data.color4})
      this.$store.commit('setGlobalAttributeBackgroundImage', data.backgroundImage);

      //Define Color CSS Variables
      const style = document.documentElement.style;
      style.setProperty('--color1', data.color1);
      style.setProperty('--color2', data.color2);
      style.setProperty('--color3', data.color3);
      style.setProperty('--color3-75', data.color3 + 'BF');
      style.setProperty('--color3-25', data.color3 + '40');
      style.setProperty('--color4', data.color4);

    }),
        //Load Menu in Vuex Store
        cpGetContentTree('mainMenu').then(data => {
          this.$store.commit('setMainMenu', data);
        }),
        //Load all SM Links in Vuex Store
        cpGetContentItems('socialmedia').then(data => {
          this.$store.commit('setSocialmediaLinks', data)
        })
  }
}
</script>
<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./assets/css/fw.min.css";
@import "./assets/css/style.css";
</style>

import { createApp } from 'vue'
import App from './App.vue'

/** Global Functions **/
export function removeFirstLastDashFromPath(path) {
    let newPath = path
    if(path.substring(0,1) == '/') {
        newPath = path.substring(1)
    }
    if(newPath.slice(-1) == '/') {
        newPath = newPath.slice(0, -1);
    }
    return newPath;
}

/**
 * create App
 * @type {App<Element>}
 */
const benigeier = createApp(App);

/**
 * Use Plugins and other helpful stuff
 */
//Vue Router & VUEX Store
import router from "@/router";
import {store} from "@/store";

benigeier.use(router);
benigeier.use(store);

benigeier.mount('#app')
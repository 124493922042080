import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView";
import linkTreeView from "@/views/LinkTreeView";
import DownloadsView from "@/views/DownloadsView";
import ReleaseView from "@/views/ReleaseView";

const routes = [
    {path: '/', name: 'landing', component: HomeView},
    {path: '/links', name: 'links', component: linkTreeView},
    {path: '/pushandpull', name: 'release pushandpull', component: ReleaseView},
    {path: '/downloads', name: 'downlaods', component: DownloadsView},
    {path: '/:pathMatch(.*)*', name: 'default', component: HomeView},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 80,

            }
        }
    }
})

router.beforeResolve(() => {
})
router.beforeEach(() => {
    window.scrollTo(0, 0);
});

export default router

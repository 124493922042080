<template>
  <header>
  <ul class="menu d-flex justify-content-between"
      v-bind:style="{backgroundImage: 'url(' + getBackgroundImageUrl + ')'}"
  >
    <li v-for="(link, index) in this.$store.getters.getMainMenu" :key="index">
      <RouterLink :to="link.link" v-html="link.title"/>
    </li>
  </ul>
  </header>
</template>

<script>
import {cpFullStorageUrl} from "@/api/cockpit";

export default {
  name: "PartHeader",
  data() {
    return {};
  },
  computed: {
    getBackgroundImageUrl() {
      return cpFullStorageUrl(this.$store.getters.getGlobalAttributeBackgroundImage.path);
    }
  },
  created() {},
}
</script>

<style scoped>
ul.menu {
  top: 0;
  padding: 1.2rem 1.5rem;
}
ul.menu a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
}

ul.menu a:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid var(--color1);
  transition: all ease var(--transition-time);
  opacity: 0;
}
 ul.menu li a:hover:after, ul.menu li a.active:after {
  bottom: -3px;
  border-bottom-color: var(--color2);
  opacity: 1;
}

</style>
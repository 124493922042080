<template>
  <div class="background-image wrapper"
       v-bind:style="{backgroundImage: 'url(' + getBackgroundImageUrl + ')'}"
  >
  <div class="container">
    <h2 class="txt-aln-center">links</h2>
    <div class=" link-wrapper">
      <a :href="link.link" target="_blank" v-for="(link, index) in this.links" :key="index" class="item">
        <div class="image" v-bind:style="{backgroundImage: 'url(' + getLinkImageUrl(link.image) + ')'}"/>
        <div class="content">
          <div class="title" v-html="link.title"/>
          <div class="info" v-html="link.info"/>
        </div>
      </a>
    </div>
  </div>
</div>
</template>

<script>
import {cpFullStorageUrl, cpGetContentTree} from "@/api/cockpit";

export default {
  name: "LinkTreeView",
  data() {
    return {
      links: [],
    };
  },
  methods: {
    getLinkImageUrl(image) {
      if (image) {
        return cpFullStorageUrl(image.path);
      }
      return '';
    }
  },
  computed: {
    getBackgroundImageUrl() {
      return cpFullStorageUrl(this.$store.getters.getGlobalAttributeBackgroundImage.path);
    },

  },
  async created() {
    cpGetContentTree('linktree').then(data => {
      this.links = data;
    })

  },
}
</script>

<style scoped>
</style>

<template>
  <footer>
    <ul class="menu "
        v-bind:style="{backgroundImage: 'url(' + getBackgroundImageUrl + ')'}"
    >
    <li v-for="(link, index) in this.$store.getters.getSocialmediaLinks" :key="index">
      <a :href="link.link" target="_blank"><i :class="link.faIcon"></i></a>
    </li>

  </ul>
  </footer>
</template>

<script>
import {cpFullStorageUrl} from "@/api/cockpit";

export default {
  name: "PartFooter",
  data() {
    return {};
  },
  computed: {
    getBackgroundImageUrl() {
      return cpFullStorageUrl(this.$store.getters.getGlobalAttributeBackgroundImage.path);
    }
  },
  created() {},
}
</script>

<style scoped>
a {
  font-size: 1.5rem;
}
</style>